import { Grid, Stack, TextField } from '@mui/material'
import { Navbar } from 'components/navbar'
import { route } from 'settings'
import { AlertComponent, ElementComponent, ModalComponent } from 'components'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { ItemDrugForm, ItemIcdForm } from 'components/form'
import { SelectBar } from 'components/element'
import { Form, Formik } from 'formik'
import { useMutation, useQuery } from 'urql'
import { MedicalMutate, MedicalQuery } from 'services/graphql/medicalService'
import { DiagnoseType } from 'types'
import { general } from 'utils'
import dayjs from 'dayjs'
import ImageForm from 'components/element/ImageForm'
import { PatientQuery } from 'services/graphql/patientService'
import { useUser } from 'stores/recoil'
import { useRecoilState } from 'recoil'

interface IConsultSubmit {
  data: {
    placeName: string
    dateConsult: string
    time: string
    ctId: string
    textConsult: string
    uid: string
    temp: string
    bp_sys: string
    bp_dia: string
    hr: string
    rr: string
    doctorUid: string
    isDiagnose: boolean
    isPayedDrug: boolean
    typeConsult: string
  }
}

function InputPage() {
  const [user] = useRecoilState(useUser.seletor.seletorUser)
  const { patientId } = useParams()
  const { cId } = useParams()
  const [icdTenValues, setIcdTenValues] = useState([
    { icdCode: '', icdName: '' },
  ])
  const [isShowApprointment, setIsShowApprointment] = useState<boolean>(false)
  const [imageData, setImageData] = useState([])
  const [hour, setHour] = useState<string>('')
  const [textConsult, setTextConsult] = useState<string>('')
  const [minute, setMinute] = useState<string>('')
  const [date, setDate] = useState<Date>()
  const [cost, setCost] = useState<number>(0.0)
  const [income, setIncome] = useState<number>(0.0)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [patientDiagnoseLastOne] = useQuery(
    PatientQuery.getDiagnoseLastOne(patientId),
  )
  const [drugValues, setDrugValues] = useState([
    {
      duId: 0,
      dName: '',
      dutId: 0,
      dutName: '',
      qty: 0,
      isPhr: 'No',
      income: 0.0,
      cost: 0.0,
      description: '',
      dUseType: '',
    },
  ])
  const handleHourList = (): SelectBar.OptionSelectType[] => {
    const hourOption = [
      '08',
      '09',
      '10',
      '11',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
    ]

    return hourOption.map((day: string) => ({
      key: day,
      value: day,
    }))
  }
  const dataInitial: any = {
    underlying: null,
    specialCase: '',
  }
  const [patientUserData] = useQuery(PatientQuery.getUserByUserId(patientId))
  const [underlyingData] = useQuery(MedicalQuery.getUnderlyingDisease())
  const [userUpdateDisease, executeUserDisease] = useMutation(
    MedicalMutate.updateUserInfo,
  )
  //const [patientData, setPatientData] = useState<any>({})
  const handleUserData = (patientDisease: any[]): any[] => {
    if (patientDisease) {
      return patientDisease.map((patient: any) => ({
        key: patient.underlyingDisease.udId,
        value: patient.underlyingDisease.medName,
      }))
    } else {
      return dataInitial.underlying
    }
  }

  const handleOptions = (underlying: any[]): any[] => {
    return underlying?.map((diseaseItem: any) => ({
      key: diseaseItem.udId,
      value: diseaseItem.medName,
    }))
  }
  useEffect(() => {
    if (
      underlyingData.fetching ||
      !underlyingData.data ||
      patientUserData.fetching ||
      !patientUserData.data
    )
      return
    setSetAllergy(
      patientUserData.data?.getUser.UserMedicalDemograhicData?.allergic,
    )
    setUnderlying(
      handleUserData(
        patientUserData.data?.getUser.UserMedicalDemograhicData
          ?.userUnderlyingDiseaseTrasaction,
      ),
    )
    //setPatientData(getUser)
    const { getUnderlyingDiseases } = underlyingData.data
    setOptionsChoice(handleOptions(getUnderlyingDiseases))
    //!disable && onChangeValue((prev) => ({ ...prev, specialCase: allergic }))
  }, [underlyingData, patientUserData])

  const handleRemoveDrugValues = (index: number) => {
    let newDrugVal = [...drugValues]
    newDrugVal.splice(index, 1)
    setDrugValues(newDrugVal)
  }

  const handleSetDrugValues = (index: number, data: any) => {
    let newDrugVal = [...drugValues]
    newDrugVal[index] = data
    setDrugValues(newDrugVal)

    let costTotal = 0.0
    let incomeTotal = 0.0
    drugValues.map((item: any) => {
      costTotal += Number.parseFloat(item.cost) * Number.parseFloat(item.qty)
      incomeTotal +=
        Number.parseFloat(item.income) * Number.parseFloat(item.qty)
    })
    setCost(costTotal)
    setIncome(incomeTotal)
  }

  const handleRemoveIcdTenValues = (index: number) => {
    let newIcdVal = [...icdTenValues]
    newIcdVal.splice(index, 1)
    setIcdTenValues(newIcdVal)
  }

  const handleSetIcdTenValues = (index: number, data: any) => {
    let newIcdVal = [...icdTenValues]
    newIcdVal[index] = data
    setIcdTenValues(newIcdVal)
  }

  const [allergy, setSetAllergy] = useState<string>('')
  const [underlying, setUnderlying] = useState<any[]>([])
  const [detail, setSetDetail] = useState<string>('')
  const [recommend, setSetRecommend] = useState<string>('')
  const [optionsChoice, setOptionsChoice] = useState<any[]>([])
  const initialValues: DiagnoseType.DiagnoseType = {
    icdData: JSON.stringify(icdTenValues),
    drugData: JSON.stringify(drugValues),
    allergy: allergy,
    detail: detail,
    recommend: recommend,
    uId: patientId,
    costTotal: cost.toFixed(2).toString(),
    incomeTotal: cost.toFixed(2).toString(),
    duId: '', //doctor_id
    //underlying: JSON.stringify(underlying),
    images: [],

    placeName: 'online',
    time: hour + '.' + minute,
    ctId: 'ปรึกษาเเพทย์โรคทั่วไป',
    dateConsult: date,
    textConsult: textConsult,
    uid: patientId,
    temp: '-',
    bp_sys: '-',
    bp_dia: '-',
    hr: '-',
    rr: '-',
    doctorUid: '-',
    isDiagnose: false,
    isPayedDrug: false,
    typeConsult: 'online',
  }

  const [submitPopupData, setSubmitPopupData] = useState<{
    type: 'success' | 'error'
    open: boolean
    title: string
    description: string
    onClose: Function
  }>({
    type: 'success',
    open: false,
    title: '',
    description: '',
    onClose: () => {},
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [createMedicalMutation, executeMedicalMutation] = useMutation(
    MedicalMutate.createDiagnose,
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [createConsultMutation, executeMutationConsult] = useMutation(
    MedicalMutate.createConsult,
  )

  const [, executeUpdateConsultMutation] = useMutation(
    MedicalMutate.updateConsult,
  )
  const formatUserDiseaseSubmit = (
    originData: any,
    newData: any,
  ): { created: string[]; deleted: string[] } => {
    const createList: string[] = []
    const deleteList: string[] = []

    if (newData.length) {
      for (const dataItem of newData) {
        if (
          !originData.some(
            (originItem: any) =>
              originItem.underlyingDisease.udId === dataItem.key,
          )
        )
          createList.push(dataItem.key)
      }

      for (const dataItem of originData) {
        if (
          !newData.some(
            (newItem: any) => newItem.key === dataItem.underlyingDisease.udId,
          )
        )
          deleteList.push(dataItem.uudtId)
      }
    }

    return {
      created: createList,
      deleted: deleteList,
    }
  }
  const createDiagnose = async (values: DiagnoseType.DiagnoseType) => {
    const { created, deleted } = formatUserDiseaseSubmit(
      patientUserData.data.getUser.UserMedicalDemograhicData
        .userUnderlyingDiseaseTrasaction,
      underlying,
    )
    if (created.length > 0 || deleted.length > 0)
      await executeUserDisease({
        data: {
          UserMedicalDemograhicData: {
            update: {
              data: {
                allergic: {
                  set: allergy,
                },
                ...(created.length > 0 || deleted.length > 0
                  ? {
                      userUnderlyingDiseaseTrasaction: {
                        ...(created.length
                          ? {
                              create: created.map((item: any) => ({
                                underlyingDisease: {
                                  connect: {
                                    udId: item,
                                  },
                                },
                              })),
                            }
                          : {}),
                        ...(deleted.length
                          ? {
                              deleteMany: deleted.map((item: any) => ({
                                uudtId: {
                                  equals: item,
                                },
                              })),
                            }
                          : {}),
                      },
                    }
                  : {}),
              },
            },
          },
        },
        where: {
          uId: patientId,
        },
      })

    let payload = {
      data: {
        icdData: values.icdData,
        drugData: values.drugData,
        allergy: values.allergy,
        detail: values.detail,
        recommend: values.recommend,
        uId: values.uId,
        costTotal: values.costTotal,
        incomeTotal: values.incomeTotal,
        duId: values.duId,
        //imageFile: values.images,
      },
      files: imageData,
    }
    const result = await executeMedicalMutation(payload)
    if (isShowApprointment) {
      let payload: IConsultSubmit = {
        data: {
          placeName: values.placeName,
          dateConsult: dayjs(values.dateConsult).format('DD/MM/YYYY'),
          time: values.time,
          ctId: values.ctId,
          textConsult: values.textConsult,
          uid: values.uid,
          temp: '-',
          bp_sys: '-',
          bp_dia: '-',
          hr: '-',
          rr: '-',
          doctorUid: user['uId'],
          isDiagnose: false,
          isPayedDrug: false,
          typeConsult: 'online',
        },
      }
      await executeMutationConsult(payload)
    }
    if (result.error) {
      setSubmitPopupData({
        type: 'error',
        open: true,
        title: 'ส่งข้อมูลไม่สำเร็จ',
        description: result.error.toString(),
        onClose: () => {
          setSubmitPopupData({
            type: 'success',
            open: false,
            title: '',
            description: '',
            onClose: () => {},
          })
        },
      })
      return
    }
    let payloadConsult = {
      data: {
        dId: { set: result.data.createDiagnose.dId },
        isDiagnose: { set: true },
        isPayedDrug: { set: false },
        finishedFlag: { set: false },
      },
      where: {
        cId: cId,
      },
    }
    await executeUpdateConsultMutation(payloadConsult)
    //alert(JSON.stringify(await executeUpdateConsultMutation(payloadConsult)))
    setSubmitPopupData({
      type: 'success',
      open: true,
      title: 'ส่งข้อมูลสำเร็จ',
      description: '',
      onClose: () => {
        general.http.goto('/input/' + patientId + '/' + cId)
      },
    })

    return
  }
  return (
    <div className="p-[40px]">
      <Grid
        container
        justifyContent="center"
        columns={{ desktop: 2.2 }}
        gap="8px"
      >
        <Grid item desktop={0.1} className=" p-[8px]" justifyContent="center">
          <Navbar
            activeMenu={route.INPUT_PAGE_URL}
            patientId={patientId}
            cId={cId}
          />
        </Grid>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          //validationSchema={validationSchema}
          onSubmit={createDiagnose}
        >
          <Form className="w-[90%]">
            <div>
              <Grid item desktop={1.9} className="">
                <div className="w-[100%] p-[16px]">
                  <Grid
                    container
                    justifyContent="center"
                    columns={{ desktop: 2.1 }}
                    gap="16px"
                    className="mt-4"
                  >
                    <Grid item desktop={1}>
                      <div>
                        <p className="text-[22px] text-blue-main mt-1">
                          วินิจฉัย
                        </p>
                        <p>ICD-10</p>
                        <Stack
                          direction={{ laptop: 'column' }}
                          className=" w-[100%] flex gap-[8px]"
                        >
                          {icdTenValues.length >= 1 &&
                            icdTenValues.map((value, index: number) => (
                              <ItemIcdForm
                                index={index}
                                onRemove={() => handleRemoveIcdTenValues(index)}
                                onSelected={(selectVal) => {
                                  if (selectVal !== null) {
                                    handleSetIcdTenValues(index, selectVal)
                                  } else {
                                    handleSetIcdTenValues(index, '')
                                  }
                                }}
                              />
                            ))}
                        </Stack>
                        <ElementComponent.Button
                          //disable={patientUserData.fetching}
                          id="submit-diagnose-button"
                          width="w-main"
                          height="32px"
                          text="เพิ่ม"
                          style="mt-2"
                          onAction={() =>
                            setIcdTenValues([
                              ...icdTenValues,
                              { icdCode: '', icdName: '' },
                            ])
                          }
                          //onAction={onClickSave}
                        />
                        <p className="mt-2">แพ้ยา/แพ้อาหาร</p>
                        <TextField
                          className="w-[100%]"
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          value={allergy}
                          onChange={(event) =>
                            setSetAllergy(event.target.value)
                          }
                        />
                        <p className="mt-2">โรคประจำตัว</p>
                        <div style={{ marginTop: '-20px' }}>
                          <ElementComponent.MultiSelectBar
                            name="underlying"
                            isLoading={!createMedicalMutation.fetching}
                            height="30px"
                            data={underlying}
                            options={optionsChoice}
                            label=""
                            placeholder="โรคประจำตัว"
                            onSelect={(options) => {
                              //alert(JSON.stringify(options))
                              setUnderlying(options)
                              //formik.setFieldValue('underlying', options)
                              /*onChangeValue((prev: any) => ({
                              ...prev,
                              underlying: options,
                            }))*/
                            }}
                          />
                        </div>
                        <p className="mt-2">รายละเอียดการตรวจ</p>
                        <TextField
                          multiline={true}
                          rows={4}
                          className="w-[100%]"
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          onChange={(event) => setSetDetail(event.target.value)}
                        />
                        <p className="mt-2">คำแนะนำ</p>
                        <TextField
                          multiline={true}
                          rows={4}
                          className="w-[100%]"
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          onChange={(event) =>
                            setSetRecommend(event.target.value)
                          }
                        />
                        <p className="text-[16px] text-white-main w-[90px]  bg-blue-main rounded-[20px] text-center mt-2 mb-2">
                          เพิ่มรูป
                        </p>
                        <ImageForm
                          key={`value`}
                          loading={false}
                          disable={false}
                          exampleImage={''}
                          onChoose={(images) => {
                            setImageData(images)
                          }}
                        />
                        {/*<SVG.BtnImageAdd />*/}
                      </div>
                    </Grid>
                    <Grid item desktop={1}>
                      <div>
                        <Stack
                          direction={{ laptop: 'column' }}
                          className=" w-[100%] flex gap-[8px]"
                        >
                          <Stack
                            direction={{ laptop: 'row' }}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <p className="text-[22px] text-blue-main mt-1">
                              วินิจฉัย
                            </p>
                            <p
                              className="text-[16px] text-white-main w-[90px]  bg-sky-300 rounded-[20px] text-center mt-2 mb-2 cursor-pointer"
                              onClick={() => setOpenModal(true)}
                            >
                              Remed
                            </p>
                          </Stack>
                          <Stack
                            direction={{ laptop: 'row' }}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <p className="w-[35%]">ชื่อยา</p>
                            <p className="w-[35%]">วิธีบริหารยา</p>
                            <p className="w-[15%]">จำนวน</p>
                            <p className="w-[10%]">PHR</p>
                          </Stack>
                          <Stack
                            direction={{ laptop: 'column' }}
                            className=" w-[100%] flex gap-[8px]"
                          >
                            {drugValues.length >= 1 &&
                              drugValues.map((value, index: number) => (
                                <ItemDrugForm
                                  index={index}
                                  onRemove={() => handleRemoveDrugValues(index)}
                                  onSelected={(selectVal) => {
                                    if (selectVal !== null) {
                                      //alert(JSON.stringify(selectVal))
                                      handleSetDrugValues(index, selectVal)
                                    } else {
                                      handleSetDrugValues(index, '')
                                    }
                                  }}
                                />
                              ))}
                            <ElementComponent.Button
                              //disable={patientUserData.fetching}
                              id="submit-diagnose-button"
                              width="w-main"
                              height="32px"
                              text="เพิ่ม"
                              style="mt-2"
                              onAction={() =>
                                setDrugValues([
                                  ...drugValues,
                                  {
                                    duId: 0,
                                    dName: '',
                                    dutId: 0,
                                    dutName: '',
                                    qty: 0,
                                    isPhr: 'No',
                                    income: 0.0,
                                    cost: 0.0,
                                    description: '',
                                    dUseType: '',
                                  },
                                ])
                              }
                            />

                            <Stack
                              direction={{ laptop: 'row' }}
                              justifyContent="space-between"
                              alignItems="center"
                              className=" w-[50%] ml-[37%]"
                            >
                              <p className="w-[45%]">ต้นทุน</p>
                              <p className="w-[45%]">รายได้</p>
                            </Stack>
                            <Stack
                              direction={{ laptop: 'column' }}
                              className=" w-[100%] flex gap-[8px]"
                            >
                              <Stack
                                direction={{ laptop: 'row' }}
                                justifyContent="space-between"
                                alignItems="center"
                                className=" w-[50%] ml-[37%]"
                              >
                                <TextField
                                  className="w-[45%]"
                                  size="small"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={cost.toFixed(2)} //ต้นทุน
                                />
                                <TextField
                                  className="w-[45%]"
                                  size="small"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={income.toFixed(2)} //ราคาขาย
                                />
                              </Stack>
                            </Stack>
                          </Stack>

                          <p className="text-[22px] text-blue-main mt-1">
                            วันนัด
                          </p>
                          <p
                            onClick={() => {
                              setIsShowApprointment(true)
                            }}
                            className="text-[16px] text-white-main w-[90px]  bg-blue-main rounded-[20px] text-center mt-2 mb-2 cursor-pointer"
                          >
                            เพิ่มการนัด
                          </p>
                          {isShowApprointment ? (
                            <div
                              className="text-[16px] rounded-[5px] p-[10px]"
                              style={{
                                borderColor: '#ccc',
                                borderWidth: '1px',
                              }}
                            >
                              <Grid
                                container
                                justifyContent="start"
                                columns={{ desktop: 3.3 }}
                                gap="8px"
                              >
                                <Grid item desktop={3.24} className="">
                                  <ElementComponent.DatePicker
                                    dateValue={null}
                                    name="createDate"
                                    isSubmitting={null}
                                    height="60px"
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    onChange={(value: string) => {
                                      setDate(dayjs(value).toDate())
                                      //(data.date = dayjs(value).toDate())
                                    }}
                                    yearLength={2}
                                  />
                                </Grid>
                                <Grid item desktop={1.6}>
                                  <SelectBar.SelectBar
                                    focusSpacing={true}
                                    isLoading={false}
                                    name="hour"
                                    label={'ชั่วโมง'}
                                    placeholder={'ชั่วโมง'}
                                    width="w-full"
                                    height={'60px'}
                                    data={hour}
                                    option={handleHourList()}
                                    onChange={(value: string) => {
                                      setHour(value)
                                      //formik.setFieldValue('day', Number(value))
                                    }}
                                  />
                                </Grid>
                                <Grid item desktop={1.6}>
                                  <SelectBar.SelectBar
                                    focusSpacing={true}
                                    isLoading={false}
                                    name="min"
                                    label={'นาที'}
                                    placeholder={'นาที'}
                                    width="w-full"
                                    height={'60px'}
                                    data={minute}
                                    option={[
                                      { key: '00', value: '00' },
                                      { key: '30', value: '30' },
                                    ]}
                                    onChange={(value: string) => {
                                      setMinute(value)
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <div>
                                <p className="mt-2">หมายเหตุการนัด</p>
                                <TextField
                                  className="w-[100%]"
                                  size="small"
                                  id="outlined-basic"
                                  variant="outlined"
                                  onChange={(event) =>
                                    setTextConsult(event.target.value)
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <div
                              className="text-[16px] text-gray-main rounded-[5px] text-center pt-[50px] pb-[50px]"
                              style={{
                                borderColor: '#ccc',
                                borderWidth: '1px',
                              }}
                            >
                              <p>ไม่มีข้อมูลวันนัด</p>
                            </div>
                          )}

                          <ElementComponent.Button
                            submit
                            id="submit-diagnose-button"
                            width="w-main"
                            height="32px"
                            text="ยืนยันส่งข้อมูล"
                            style="mt-2 ml-[40%]"
                            loading={
                              userUpdateDisease.fetching ||
                              createMedicalMutation.fetching ||
                              createConsultMutation.fetching
                            }
                            //onAction={() => alert(JSON.stringify(drugValues))}
                          />
                        </Stack>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </div>
          </Form>
        </Formik>
        {submitPopupData.type === 'success' ? (
          <AlertComponent.SuccessModal
            open={submitPopupData.open}
            title={submitPopupData.title}
            description={submitPopupData.description}
            onClose={submitPopupData.onClose}
          />
        ) : (
          <AlertComponent.ErrorModal
            open={submitPopupData.open}
            title={submitPopupData.title}
            description={submitPopupData.description}
            onClose={submitPopupData.onClose}
          />
        )}
      </Grid>
      {!patientDiagnoseLastOne.fetching && !patientDiagnoseLastOne.error && (
        <ModalComponent.DiagnoseModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          data={patientDiagnoseLastOne.data}
          canChooseDrug
          onChooseDrug={() => {}}
        />
      )}
    </div>
  )
}
export default InputPage
