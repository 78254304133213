import {
  Stack,
  Autocomplete,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from '@mui/material'
import { useState } from 'react'
import { MedicalQuery } from 'services/graphql/medicalService'
import { useQuery } from 'urql'

import * as SVG from 'assets/svg'
interface ItemDrugFormProps {
  index: number
  onRemove?: Function
  onSelected?: Function
}

export default function ItemDrugForm({
  index,
  onRemove,
  onSelected,
}: ItemDrugFormProps) {
  const [open, setOpen] = useState(false)
  const [drugKeyCode, setDrugKeyCode] = useState<string>('AAA')
  const [inStock, setInStock] = useState<number>(0)
  const [isPhr, setIsPhr] = useState<string>('No')
  const [isInj, setIsInj] = useState<boolean>(false)
  const [drugDefaultCondition, setDrugDefaultCondition] = useState([])
  const [drugApiData] = useQuery(MedicalQuery.getDrugListWithName(drugKeyCode))
  const [defalutValue] = useState({
    duId: 0,
    dName: '',
    dutId: 0,
    dutName: '',
    qty: 0,
    isPhr: 'No',
    income: 0.0,
    cost: 0.0,
    typeOne: '',
    quantity: '',
    unit: '',
    description: '',
    dUseType: '',
    invoiceType: '',
    injDefault: '',
    injMin: '',
    injMax: '',
    injUnit: '',
    injIM: '',
    injIV: '',
    injSC: '',
    injChooseTypeVal: '',
    injQtyVal: '',
    tradeName: '',
  })
  const drugPreData = []
  if (!drugApiData.fetching && !drugApiData.error) {
    drugApiData.data.getDrugList.map((item: any) =>
      drugPreData.push({
        label:
          item.dName +
          ' (' +
          item.tradeName +
          ') ' +
          item.quantity +
          ' ' +
          item.unit,
        duId: item.duId,
        dName: item.dName,
        stock: item.stock,
        phrDrug: item.phrDrug,
        drugDefaultCondition: item.drugDefaultCondition,
        quantity: item.quantity,
        unit: item.unit,
        income: item.income,
        cost: item.cost,
        typeOne: item.typeOne,
        description: item.description,
        dUseType: item.dUseType,
        invoiceType: item.invoiceType,
        injDefault: item.injDefault,
        injMin: item.injMin,
        injMax: item.injMax,
        injUnit: item.injUnit,
        injIM: item.injIM,
        injIV: item.injIV,
        injSC: item.injSC,
        tradeName: item.tradeName,
      }),
    )
  }
  const onTextConsultChange = (event) => {
    if (event.target.value.length > 3) {
      setDrugKeyCode(event.target.value)
    }
  }
  return (
    <Stack
      direction={{ laptop: 'row' }}
      justifyContent="space-between"
      alignItems="center"
    >
      {!drugApiData.fetching && !drugApiData.error && (
        //alert(JSON.stringify())
        <>
          <Autocomplete
            open={open}
            onInputChange={(_, value) => {
              if (value.length === 0) {
                if (open) setOpen(false)
              } else {
                if (!open) setOpen(true)
              }
            }}
            onChange={(_, value) => {
              if (value !== null) {
                defalutValue.duId = value.duId
                defalutValue.dName = value.dName
                defalutValue.isPhr = value.phrDrug
                defalutValue.cost = value.cost
                defalutValue.income = value.income
                defalutValue.typeOne = value.typeOne
                defalutValue.quantity = value.quantity
                defalutValue.unit = value.unit
                defalutValue.description = value.description
                defalutValue.dUseType = value.dUseType
                defalutValue.invoiceType = value.invoiceType

                defalutValue.injDefault = value.injDefault
                defalutValue.injMin = value.injMin
                defalutValue.injMax = value.injMax
                defalutValue.injUnit = value.injUnit
                defalutValue.injIM = value.injIM
                defalutValue.injIV = value.injIV
                defalutValue.injSC = value.injSC
                defalutValue.injQtyVal = value.injDefault
                defalutValue.tradeName = value.tradeName
                if (value.dUseType === 'ยาฉีด') {
                  setIsInj(true)
                } else {
                  setIsInj(false)
                }
                setIsPhr(value.phrDrug)
                setInStock(value.stock)
                const conditionString = value.drugDefaultCondition
                if (Array.isArray(JSON.parse(conditionString).data)) {
                  const conditions = JSON.parse(conditionString).data
                  setDrugDefaultCondition(conditions)
                }
              }
              onSelected(defalutValue)
            }}
            onClose={() => setOpen(false)}
            disablePortal
            id={'combo-box-demo-' + index}
            className="w-[35%]"
            options={drugPreData}
            noOptionsText="ไม่มีข้อมูล พิมพ์ชื่อยาเพื่อค้นหา"
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                onChange={onTextConsultChange}
              />
            )}
          />
          {!isInj ? (
            <FormControl className="w-[35%]" size="small">
              <Select
                name="dutId"
                onChange={(event: SelectChangeEvent) => {
                  const dataSplit = event.target.value.split(':')
                  defalutValue.dutId = Number.parseInt(dataSplit[0])
                  defalutValue.dutName = dataSplit[1]
                  //alert(event.target.name)
                  onSelected(defalutValue)
                }}
              >
                {drugDefaultCondition.length > 0 &&
                  drugDefaultCondition.map((item: any) => (
                    <MenuItem value={item.dutId + ':' + item.dutName}>
                      {item.dutName}
                    </MenuItem>
                  ))}
              </Select>
              {/*<FormHelperText>Without label</FormHelperText>*/}
            </FormControl>
          ) : (
            <Stack
              direction={{ laptop: 'row' }}
              justifyContent="space-between"
              alignItems="center"
              className="w-[40%] ml-1 mr-1"
            >
              <TextField
                className="w-[40%]"
                size="small"
                id="outlined-basic"
                variant="outlined"
                inputMode="numeric"
                type="text"
                defaultValue={defalutValue.injDefault}
                onChange={(event) => {
                  defalutValue.injQtyVal = event.target.value
                  onSelected(defalutValue)
                }}
              />
              <p className="w-[20%] text-center">{defalutValue.injUnit}</p>

              <FormControl className="w-[40%]" size="small">
                <Select
                  onChange={(event: SelectChangeEvent) => {
                    defalutValue.injChooseTypeVal = event.target.value
                    //alert(event.target.name)
                    onSelected(defalutValue)
                  }}
                >
                  {defalutValue.injIM === 'Yes' && (
                    <MenuItem value="IM">IM</MenuItem>
                  )}
                  {defalutValue.injIV === 'Yes' && (
                    <MenuItem value="IV">IV</MenuItem>
                  )}
                  {defalutValue.injSC === 'Yes' && (
                    <MenuItem value="SC">IV</MenuItem>
                  )}
                </Select>
                {/*<FormHelperText>Without label</FormHelperText>*/}
              </FormControl>
            </Stack>
          )}

          <TextField
            className="w-[15%]"
            size="small"
            id="outlined-basic"
            variant="outlined"
            inputMode="numeric"
            type="number"
            defaultValue="0"
            InputProps={{ inputProps: { min: 0, max: inStock } }}
            onChange={(event) => {
              defalutValue.qty = Number.parseInt(event.target.value)
              onSelected(defalutValue)
            }}
          />
          <div onClick={() => onRemove()}>
            <SVG.BtnDelete />
          </div>
          <Checkbox checked={isPhr === 'Yes' ? true : false} />
        </>
      )}
    </Stack>
  )
}
